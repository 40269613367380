import React from 'react';
import { createBrowserRouter, RouterProvider, useRouteError } from "react-router-dom";
import routes from "./Routes";
import Layout from "./Routes/Layout";
import Page500 from './components/Page500/Page500';
import Page404 from './components/Page404/Page404';
import './App.scss';

function ErrorBoundary() {
  const error = useRouteError();
  if (error.status === 500) {
    return <Page500 />;
  }
  return <Page404 />;
}

function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <ErrorBoundary />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
