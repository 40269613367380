import React from "react";
import pathConstants from '../Routes/pathConstants';

const Home = React.lazy(() => import("../components/Home/Home"));
const ViewCart = React.lazy(() => import("../components/ViewCart/ViewCart"));
const Page404 = React.lazy(() => import("../components/Page404/Page404"));


const routes = [
    { path: pathConstants.Home, element: <Home /> },
    { path: pathConstants.ViewCart, element: <ViewCart /> },
    { path: pathConstants.Page404, element: <Page404 /> },
]

export default routes;