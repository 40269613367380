import React, { useEffect } from 'react'
import page404 from "../../Assets/Img/page404.png"
import "./Page404.scss"
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom';

function Page404() {

  const navigate = useNavigate();

  let GoToBack = () => { 

    navigate('/');

  }

  return (
    <div>
      <div className='page404'>
        <div className='page404-img'>

          <img src={page404}></img>

        </div>
        <div className='page404-text'>
          <p>Oops...</p>
          <p>This is wrong. This page does no longer exist, or it never had.</p>
          <Button className='page404-btn' severity="info"  onClick={() => GoToBack()}>GO BACK HOME</Button>
        </div>
      </div>
    </div>
  )
}

export default Page404;