import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

function Layout() {
    return (
        <React.Fragment>
            <div className='App'>
                <Suspense><Outlet /></Suspense>
            </div>
        </React.Fragment>
    )
}

export default Layout